import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/BrandFooter/brandfooter.module.scss");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/BrandArticles/brandArticles.module.scss");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/BrandHeader/Hamburger.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/CategoriesTopNews/categoriesTopNews.module.scss");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/BrandHeader/brandheader.module.scss");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/BrandLandingSection/brandlandingsection.module.scss");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/VideoPlayer/VideoPlayerContainer.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/brandcomponents/VideoPlayer/VideoPlayerMobile.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/HomePage/utils/pageLoadEvent.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/components/Product/ClientAnchorTag/ClientAnchorTag.tsx")